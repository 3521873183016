import React from 'react';
import Seo from '../components/Seo';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import HeroImage from '../svg/HeroImage';
import servicesData from '../data/services-data';
import CallToAction from '../components/CallToAction';

export default () => (
  <Layout>
    <Seo title="Services" page="services" />
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
        <div className="text-center lg:text-right lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Services</h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">Taxes, Accounting, and Analytics</p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">Contact Us</Button>
          </p>
          <p className="mt-4 text-gray-600">Let us know how we can help you</p>
        </div>
      </div>
    </section>
    {servicesData.map(service => (
      <section key={service.slug} id={service.slug} className="container mx-auto pt-32 lg:pt-20">
        <h2 className="text-3xl lg:text-5xl font-semibold bg-blue-600 text-white text-center">
          {service.title}
        </h2>
        <service.component />
      </section>
    ))}
    <CallToAction />
  </Layout>
);
